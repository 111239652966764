"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const style = __importStar(require("./styled"));
const useOnOutsideClick_1 = require("@/Hooks/useOnOutsideClick");
const svg_components_1 = require("@/assets/svg-components");
const Hooks_1 = require("@/Hooks");
const FavoriteShiptosSelect = ({ options, onChange }) => {
    const placeholder = '/haldex/favorite_shipping_address/add_address';
    const { t } = (0, Hooks_1.useTranslate)();
    const [open, setOpen] = (0, react_1.useState)(false);
    const [searchOptions, setSearchOptions] = (0, react_1.useState)(options);
    const [search, setSearch] = (0, react_1.useState)('');
    const selectRef = (0, react_1.useRef)(null);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeSearch = (e) => {
        const searchValue = e.currentTarget.value;
        setSearch(searchValue);
        if (!search) {
            setSearchOptions(options);
            return;
        }
        setSearchOptions(options.filter(option => `${option.customerId.toLowerCase()} ${option.name.toLowerCase()}`.includes(searchValue.toLowerCase())));
    };
    const handleSelectOption = (option) => {
        onChange(option);
        setSearch(`${option.customerId} ${option.name}`);
        handleClose();
    };
    (0, useOnOutsideClick_1.useOnOutsideClick)(selectRef, handleClose);
    return (react_1.default.createElement(style.SelectContainer, { ref: selectRef },
        react_1.default.createElement(style.SelectHeader, null,
            react_1.default.createElement(style.SelectHeaderInput, { placeholder: t(placeholder), type: "text", value: search, onChange: handleChangeSearch, onFocus: handleOpen }),
            react_1.default.createElement(style.SelectHeaderButton, { onClick: () => setOpen(!open), type: "button" },
                react_1.default.createElement(style.Arrow, { isOpen: open }))),
        open && (react_1.default.createElement(style.DropdownListContainer, null,
            !searchOptions.length && 'No results',
            searchOptions
                .filter(option => option.isFavorite)
                .map((option, index, arr) => (react_1.default.createElement(style.DropdownListItem, { onClick: () => handleSelectOption(option), isLast: index === arr.length - 1, isFavorite: option.isFavorite },
                option.customerId,
                " ",
                option.name,
                react_1.default.createElement(style.Star, { isFavorite: option.isFavorite },
                    react_1.default.createElement(svg_components_1.StarSmallIcon, null))))),
            searchOptions.map(option => (react_1.default.createElement(style.DropdownListItem, { isFavorite: option.isFavorite, onClick: () => handleSelectOption(option) },
                option.customerId,
                " ",
                option.name,
                option.isFavorite && (react_1.default.createElement(style.Star, { isFavorite: option.isFavorite },
                    react_1.default.createElement(svg_components_1.StarSmallIcon, null))))))))));
};
exports.default = FavoriteShiptosSelect;
